$img-path: '../img';

i[class*='icon-'] {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

$icon: lang, drop, close2, drop2, en, zh, jp, ko, star, arrow, arrow2, right, menu;
@each $item in $icon {
  .icon-#{$item} {
    background-image: url($img-path + '/icon/#{$item}.svg');
  }
}

[class*='img-'] {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

$PC: blockBg1, blockBg2, blockBg3, blockBg4, blockBg6, blockBg7, introduction0, introduction1, introduction2, introduction3, numBg, jackpotBg, map0, map1, map2, map3, map4, business0, business1, businessBg, connectBg, banner0, banner1, venuesBg;
@each $item in $PC {
  .img-#{$item} {
    background-image: url($img-path + '/PC/#{$item}.png');
  }
}

$H5: banner0, banner1, numBg, jackpotBg, map0, map1, map2, map3, map4, businessBg, venuesBg;
@each $item in $H5 {
  .img-H5#{$item} {
    background-image: url($img-path + '/H5/#{$item}.png');
  }
}
